import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {ToggleButton} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";

const Pick = (props) => {
    const {isMobile, number, selectedPick, selectionChanged, setShowPickModal} = props;

    const [checked, setChecked] = useState(selectedPick !== null);

    useEffect(() => {
        if (selectedPick !== null) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [checked, selectionChanged, selectedPick]);

    const renderMobile = () => {
        return (
            <ToggleButton
                id={`toggle_check_${number}`}
                type="checkbox"
                variant="primary"
                checked={checked}
                value={number}
                onChange={(e) => {
                    // setChecked(!checked);
                    setShowPickModal(true);
                }}
                onClick={() => {
                    setShowPickModal(true);
                }}
                className={`btn ${checked ? 'btn-pick-selected' : 'btn-pick text-black'} text-uppercase roboto font-15 pt-3 pb-3`}
            >
                Pick {number}
                {
                    checked && <FontAwesomeIcon icon={faCheckCircle} className={'ms-1'}/>
                }
            </ToggleButton>
        );
    }

    const renderDesktop = () => {
        return (
            <ToggleButton
                id={`toggle_check_${number}`}
                type="checkbox"
                variant="primary"
                checked={checked}
                value={number}
                onChange={(e) => {
                    // setChecked(!checked);
                }}
                onClick={() => {
                    setShowPickModal(true);
                }}
                className={`btn ${checked ? 'btn-skins-red' : 'btn-pick text-black'} text-uppercase roboto font-18 ps-5 pe-5`}
            >
                Pick {number}
                {
                    checked && <FontAwesomeIcon icon={faCheckCircle} className={'ms-2'}/>
                }
            </ToggleButton>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render()
}

Pick.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    number: PropTypes.number.isRequired,
    selectedPick: (PropTypes.object || null).isRequired,
};

export default Pick;
