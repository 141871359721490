let SCREEN_SIZE = {
    'MOBILE': 600,
    'TABLET': 786,
    'LAPTOP': 1024,
    'LAPTOP_LG': 1400,
    'LAPTOP_XL': 1900,
    'LAPTOP_XXL': 2160,
};

export {SCREEN_SIZE};

