import ContactUs from "./ContactUs";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {AiFillAndroid, AiFillApple} from "react-icons/ai";
import {Link} from "react-router-dom";
import BottomFooter from "./BottomFooter";

const SkinsFooter = (props) => {

    const {isMobile} = props;

    const renderTop = () => {
        return (
            <>
                <div className={'text-center pt-5'}>
                    {/*<img
                        src={require('../assets/images/wp-content/uploads/2021/10/Footer-Logo.png')}
                        className="d-inline-block align-top"
                        alt="Sport Skins"
                    />*/}
                    <div className={'text-center pt-5'}>
                        <h2 className={'text-white cabin font-48'}>Contact Us</h2>
                    </div>
                    {/*<div className={'text-center mb-5'}>
                        <p className={'text-skins-medium-light-grey cabin font-17'}>
                            We welcome all enquiries and would be more than delighted to assist you in any way we can.
                            To get in touch, complete our contact form below or email
                            <a className={'ms-2 skins-link'} href={'mailto:info@sportskins.uk'}>info@sportskins.uk</a>
                            .
                        </p>
                    </div>*/}
                </div>
            </>
        );
    }
    /*const renderLeft = () => {
        return (
            <Container>
                <section key={'footer_links'}>
                    <div className={'text-white cabin font-24'}>
                        Links
                    </div>
                    <div className={'border-top mt-4 border-black pt-2'}>
                        <Row>
                            <Col className={'cabin text-white font-18'}>
                                Home
                            </Col>
                            <Col className={'cabin text-white font-18'}>
                                Fixtures
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'cabin text-white font-18'}>
                                How to Play
                            </Col>
                            <Col className={'cabin text-white font-18'}>
                                Gameweeks
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'cabin text-white font-18'}>
                                Game Rules
                            </Col>
                            <Col className={'cabin text-white font-18'}>
                                FAQs
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'cabin text-white font-18'}>
                                Contact
                            </Col>
                        </Row>
                    </div>
                </section>
                <section key={'footer_contact_left'} className={'pt-5 fadeInRight'}>
                    <div className={'text-white cabin font-24'}>
                        Contact
                    </div>
                    <div className={'border-top mt-4 border-black pt-2 text-white'}>
                        E. <a className={'ms-2 skins-link'}
                              href={'mailto:support@sportskins.uk'}>support@sportskins.uk</a>
                    </div>
                </section>
                <section key={'footer_follow_us'} className={'pt-5'}>
                    <div className={'text-white cabin font-24'}>
                        Follow Us
                    </div>
                    <div className={'border-top mt-4 border-black pt-2 text-white'}>
                        <ul className="list-unstyled list-inline">
                            <li className="ps-1 pe-2 list-inline-item">
                                <a href="#">
                                    <FontAwesomeIcon icon={faFacebookF} size={'1x'} className={'text-white'}/>
                                </a>
                            </li>
                            <li className="ps-1 pe-2 list-inline-item">
                                <a href="#">
                                    <FontAwesomeIcon icon={faInstagram} size={'1x'} className={'text-white'}/>
                                </a>
                            </li>
                            <li className="ps-1 pe-2 list-inline-item">
                                <a href="#">
                                    <FontAwesomeIcon icon={faTwitter} size={'1x'} className={'text-white'}/>
                                </a>
                            </li>
                            <li className="ps-1 pe-2 list-inline-item">
                                <a href="#">
                                    <FontAwesomeIcon icon={faYoutube} size={'1x'} className={'text-white'}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
            </Container>
        );
    }*/

    const renderMoreLinks = () => {
        return (
            <>
                <Link to={'/cookie-policy'} className={'sfProDisplay font-20 fw-300 footer-links d-block'}>
                    Cookie Policy
                </Link>
                <Link to={'/contact-us'} className={'sfProDisplay font-20 fw-300 footer-links d-block'}>
                    Contact Us
                </Link>
            </>
        );
    }

    const renderLinks = () => {
        return (
            <>
                <div className={'d-flex justify-content-evenly align-items-center mt-5'}>
                    <div className={'w-100'}>
                        <hr className={'skins-footer-hr'}/>
                    </div>
                    <div className={'ps-4 pe-4'}>
                        <img
                            src={require('../assets/images/wp-content/uploads/2021/10/Footer-Logo.png')}
                            className="d-inline-block align-top img-fluid"
                            alt="Sport Skins"
                        />
                    </div>
                    <div className={'w-100'}>
                        <hr className={'skins-footer-hr'}/>
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-top mb-5 text-white'}>
                    <div>
                        <div className={'sfProDisplay font-20 fw-300'}>
                            Downloads
                        </div>
                        <div className={'sfProDisplay font-20 fw-300'}>
                            <AiFillApple size={35} className={'text-skins-teal pe-2'}/>
                            App Store
                        </div>
                        <div className={'sfProDisplay font-20 fw-300'}>
                            <AiFillAndroid size={35} className={'text-skins-teal pe-2'}/>
                            Google Play
                        </div>
                    </div>
                    <div>
                        <Link to={'/privacy-policy'} className={'sfProDisplay font-20 fw-300 footer-links d-block'}>
                            Privacy Policy
                        </Link>
                        <Link to={'/terms-and-conditions'}
                              className={'sfProDisplay font-20 fw-300 footer-links d-block'}>
                            Terms & Conditions
                        </Link>
                        <Link to={'/responsible-gambling'}
                              className={'sfProDisplay font-20 fw-300 footer-links d-block'}>
                            Responsible Gambling
                        </Link>
                        {isMobile && renderMoreLinks()}
                    </div>
                    {
                        !isMobile && renderMoreLinks()
                    }
                </div>
            </>
        );
    }
    const render = () => {
        return (
            <section className={'bg-white'}>
                <div className={'bg-dark-blue footer-bg pb-4'}>
                    <Container fluid>
                        {renderTop()}
                        <Container>
                            <Row>
                                {/*<Col md={3}>
                                    {renderLeft()}
                                </Col>*/}
                                <Col className={'fadeInRight'}>
                                    <ContactUs/>
                                </Col>
                            </Row>
                            {renderLinks()}
                            <BottomFooter/>
                        </Container>
                    </Container>
                </div>
            </section>
        );
    }

    return render();
}

export default SkinsFooter;
