import {Button, CloseButton, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const AlertModal = (props) => {

    const {showModal, setShowModal, withHeading, heading, body, isSuccess, handleClose} = props;

    const handleClick = () => {
        setShowModal(false);
        if (handleClose !== undefined) {
            handleClose();
        }
    }

    useEffect(() => {

    }, [showModal]);

    const render = () => {
        return (
            <Modal
                show={showModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {
                    withHeading &&
                    (
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {heading}
                            </Modal.Title>
                            <CloseButton className={'float-end'} onClick={handleClick}/>
                        </Modal.Header>
                    )
                }
                <Modal.Body className={'text-center'}>
                    <h4 className={'mb-4'}>
                        {
                            isSuccess ?
                                (<FontAwesomeIcon icon={faCheckCircle} className={'text-success'}/>)
                                :
                                (<FontAwesomeIcon icon={faTimesCircle} className={'text-danger'}/>)
                        }
                    </h4>
                    <p>
                        {body}
                    </p>
                </Modal.Body>
                {/*<Modal.Footer>*/}
                <Button onClick={handleClick}
                        className={`rounded-0 ${isSuccess ? 'btn-success' : 'btn-danger'}`}>Close</Button>
                {/*</Modal.Footer>*/}
            </Modal>
        );
    }

    return render();

}

AlertModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
    isSuccess: PropTypes.bool.isRequired,
    withHeading: PropTypes.bool.isRequired,
    heading: function (props, propName) {
        if ((props['withHeading'] && (props[propName] === undefined || typeof (props[propName]) != 'string'))) {
            return new Error('Please provide a heading!');
        }
    },
    body: PropTypes.string.isRequired,
}

export default AlertModal;
