import PropTypes from "prop-types";

const GameWeek = (props) => {

    const {isMobile, text} = props;

    const renderMobile = () => {
        return (
            <span className={'text-white roboto fw-bold font-16 text-uppercase'}>
                {text}
            </span>
        );
    }
    const renderDesktop = () => {
        return (
            <div className={'text-white roboto fw-bold font-27 text-uppercase p-2'}>
                {text}
            </div>
        );
    }
    const render = () => {
        if (isMobile) {
            return renderMobile();
        }
        return renderDesktop();
    }

    return render();
}

GameWeek.propTypes = {
    text: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired
}

export default GameWeek;
