import PropTypes from "prop-types";
import {useEffect} from "react";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import Pick from "./Pick";

const Picks = (props) => {
    const {isMobile, showPicks, selectedPicks, selectionChanged, setShowPickModal, isEdit, pickToEdit, currentWeek} = props;

    useEffect(() => {
    }, [selectionChanged, selectedPicks, isEdit, currentWeek]);

    const renderMobile = () => {
        return renderDesktop();
    }

    const renderDesktop = () => {
        let showPick1 = (showPicks?.pick_1 && !isEdit) || (isEdit && pickToEdit.number === 1);
        let showPick2 = (showPicks?.pick_2 && !isEdit) || (isEdit && pickToEdit.number === 2);
        let showPick3 = (showPicks?.pick_3 && !isEdit) || (isEdit && pickToEdit.number === 3);
        return (
            <section aria-label={'picks'}>
                <Container>
                    <Row>
                        {showPick1 &&
                            <Col className={'text-center justify-content-center d-flex align-items-center'}>
                                <Pick isMobile={isMobile} number={1} selectionChanged={selectionChanged}
                                      selectedPick={selectedPicks?.pick_1} setShowPickModal={setShowPickModal}/>
                            </Col>
                        }
                        {showPick2 &&
                            <Col className={'text-center justify-content-center d-flex align-items-center'}>
                                <Pick isMobile={isMobile} number={2} selectionChanged={selectionChanged}
                                      selectedPick={selectedPicks?.pick_2} setShowPickModal={setShowPickModal}/>
                            </Col>
                        }
                        {showPick3 &&
                            <Col className={'text-center justify-content-center d-flex align-items-center'}>
                                <Pick isMobile={isMobile} number={3} selectionChanged={selectionChanged}
                                      selectedPick={selectedPicks?.pick_3} setShowPickModal={setShowPickModal}/>
                            </Col>
                        }
                    </Row>
                </Container>
            </section>
        );
    }

    const render = () => {

        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return currentWeek ? render() : <></>;
}

Picks.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    showPicks: PropTypes.object.isRequired || null,
    selectedPicks: PropTypes.object.isRequired || null,
};

export default Picks;
