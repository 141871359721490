// export const BASE_URL = 'http://127.0.0.1:8009/';
export const BASE_URL = 'https://api.sportskins.uk/';
// export const BASE_URL = 'https://sportskins.codestudio.solutions/';
export const STAGING_BASE_URL = 'https://sportskins.codestudio.solutions/';

export const CONTACT_US = "api/v1/contact";
export const LOGIN = "api/v1/user/login";
export const FORGOT_PASSWORD = "api/v1/user/forgot/password";
export const SETTINGS = "api/v1/settings";
export const MY_SKINS = "api/v1/user/skins/picks1?status=";
export const SKINS = "api/v1/skins?type=";
export const SKIN_SINGLE = "api/v1/skins?skin_id=__SKIN_ID__";
export const WEEKS_BY_SKIN = "api/v1/skins/__SKIN_ID__/weeks";
export const MATCHES_BY_WEEK = "api/v1/skins/week/__WEEK_ID__/matches1";
export const STORE_USER_SKIN_PICKS = "api/v1/user/skins/picks";
export const BLOG_BY_SKIN_WEEK_TYPE = "api/v1/blog";
export const BLOG_BY_SLUG = "api/v1/blog/slug";
export const LATEST_BLOG_BY_SKIN = "api/v1/blog/__SKIN_ID__/latest";
