import {Button, Col, Form, Row} from "react-bootstrap";
/*import * as formik from 'formik';*/
import {Formik} from 'formik';
import * as yup from 'yup';
import {post} from "../Axios/post";
import {CONTACT_US, STAGING_BASE_URL} from "../Axios/EndPoints";
import {useEffect, useState} from "react";
import SmallLoader from "./SmallLoader";
import AlertModal from "./AlertModal";
import {useSearchParams} from "react-router-dom";

const ContactUs = () => {
    const [params] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [sentSuccess, setSentSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const schema = yup.object().shape({
        firstName: yup.string().required(),
        surname: yup.string().required(),
        email: yup.string().email().required(),
        phone: yup.string().required(),
        message: yup.string().required(),
    });

    const handleSubmit = (values) => {
        setLoading(true);
        Object.assign(values, {username: values.surname});
        let isStaging = parseInt(params.get('staging'));
        post(CONTACT_US, values, false, '', null, isStaging ? STAGING_BASE_URL : '')
            .then((response) => {
                console.log(response);
                if (response?.data?.success) {
                    values.firstName = '';
                    values.surname = '';
                    values.email = '';
                    values.phone = '';
                    values.message = '';
                    setSentSuccess(true);
                }
            })
            .catch((error) => {
                setSentSuccess(false);
                console.error('contact submit error', error.response);
            })
            .finally(() => {
                setLoading(false);
                setShowModal(true);
            })
    }

    useEffect(() => {

    }, [showModal]);

    const renderForm = () => {
        return (
            <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={{
                    firstName: '',
                    surname: '',
                    email: '',
                    phone: '',
                    message: '',
                }}
                enableReinitialize={true}
            >
                {({handleSubmit, handleChange, values, touched, errors}) => (
                    <Form className={'contact-form mt-4 pt-3'} noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        id={'firstName'}
                                        type="text" placeholder="First name *"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        isValid={touched.firstName && !errors.firstName}
                                        isInvalid={!!errors.firstName}
                                    />
                                    <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.firstName + ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        id={'surname'}
                                        type="text" placeholder="Surname *"
                                        value={values.surname}
                                        onChange={handleChange}
                                        isValid={touched.surname && !errors.surname}
                                        isInvalid={!!errors.surname}
                                    />
                                    <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.surname + ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        id={'email'}
                                        type="email" placeholder="Email Address *"
                                        value={values.email}
                                        onChange={handleChange}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.email + ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        id={'phone'}
                                        type="phone" placeholder="Phone number *"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isValid={touched.phone && !errors.phone}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.phone + ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        id={'message'}
                                        as="textarea" rows={4} placeholder={'Your message *'}
                                        value={values.message}
                                        onChange={handleChange}
                                        isValid={touched.message && !errors.message}
                                        isInvalid={!!errors.message}
                                    />
                                    <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.message + ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {/*<Col className={'d-flex flex-column justify-content-start align-items-start'}>
                                <Button className={'ps-5 pe-5'} type={'submit'} disabled={loading}>
                                    <SmallLoader loading={loading}/>
                                    {
                                        !loading &&
                                        (
                                            <>
                                                Send
                                                <img src={footerButtonArrow} alt={"footer button arrow"}
                                                     className={'text-white ms-2 img-fluid'}/>
                                            </>
                                        )
                                    }
                                </Button>
                            </Col>*/}
                        </Row>
                        <Row>
                            <Col className={'d-flex flex-column justify-content-start align-items-start'}>
                                <Button className={'ps-5 pe-5'} type={'submit'} disabled={loading}>
                                    <SmallLoader loading={loading}/>
                                    {
                                        !loading &&
                                        (
                                            <>
                                                Send
                                                {/*<img src={footerButtonArrow} alt={"footer button arrow"}
                                                     className={'text-white ms-2 img-fluid'}/>*/}
                                            </>
                                        )
                                    }
                                </Button>
                            </Col>
                            {/*<Col>
                                <a href={'https://www.begambleaware.org/'} target={'_blank'}>
                                    <img
                                        src={require('../assets/images/wp-content/uploads/2021/11/Gamble-aware-Logo-footer.png')}
                                        className={'img-fluid'}
                                        alt="be_gamble_aware_footer_image"/>
                                </a>
                            </Col>*/}
                        </Row>
                    </Form>
                )}
            </Formik>
        );
    }

    const render = () => {
        return (
            <section key={'footer_contact_form'}>
                <AlertModal showModal={showModal} withHeading={false}
                            setShowModal={setShowModal}
                            body={sentSuccess ? 'Message sent successfully!' : 'Some error occurred. Please try again!'}
                            isSuccess={sentSuccess}/>
                {/*<div className={'text-white cabin font-24'}>
                    Contact Form
                </div>*/}
                {renderForm()}
            </section>
        );
    }

    return render();
}

export default ContactUs;
