import PropTypes from "prop-types";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import WeeksList from "../Components/Matches/WeeksList";
import Container from "react-bootstrap/Container";
import SkinInfo from "../Components/Matches/SkinInfo";
import {useEffect, useState} from "react";
import Picks from "../Components/Matches/Picks";
import MatchesList from "../Components/Matches/MatchesList";
import {get} from "../Axios/get";
import {SKIN_SINGLE, STAGING_BASE_URL} from "../Axios/EndPoints";
import {useDispatch, useSelector} from "react-redux";
import SmallLoader from "../Components/SmallLoader";
import SelectedPicksModal from "../Components/SelectedPicksModal";
import AlertModal from "../Components/AlertModal";
import moment from "moment-timezone";
import {Helmet} from "react-helmet";

const Matches = (props) => {
    const {skinId, weekId} = useParams();
    const {state} = useLocation();
    const {isMobile} = props;
    const user = useSelector(state => state.Login);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [params] = useSearchParams();

    const [skinInfo, setSkinInfo] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(false);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [skinLoading, setSkinLoading] = useState(true);
    const [showPicks, setShowPicks] = useState(null);
    const [selectedPicks, setSelectedPicks] = useState(null);
    const [showPickModal, setShowPickModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showDeadlineModal, setShowDeadlineModal] = useState(false);
    const [selectionAllowed, setSelectionAllowed] = useState(false);
    const [pickNumber, setPickNumber] = useState(1);
    const [availablePicks, setAvailablePicks] = useState(1);
    const [selectionChanged, setSelectionChanged] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [pickToEdit, setPickToEdit] = useState(null);

    const handlePick = (selection) => {
        const deadline = selectedWeek?.start_date || skinInfo?.game_deadline;
        const nowTime = moment().tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
        // const nowTime = moment('2023-09-30 15:35:00').tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
        const deadlineTime = moment(deadline).format("YYYY-MM-DDTHH:mm:ss");
        let isDeadlineReached = nowTime >= deadlineTime;
        // console.log(deadline, nowTime, deadlineTime, isDeadlineReached);
        if (isDeadlineReached) {
            setShowDeadlineModal(true);
            return false;
        }

        let tempSelectedPicks = selectedPicks;
        if (showPicks?.pick_1 && !selectedPicks.pick_1) {
            tempSelectedPicks.pick_1 = {
                match_id: selection.match_id,
                team_id: selection.team_id,
                status: "",
                goalserve_id: selection.team.goalserve_id,
                name: selection.team.name,
                alt_name: selection.team.alt_name,
                icon: selection.team.icon
            };
        } else if (showPicks?.pick_2 && !selectedPicks.pick_2) {
            tempSelectedPicks.pick_2 = {
                match_id: selection.match_id,
                team_id: selection.team_id,
                status: "",
                goalserve_id: selection.team.goalserve_id,
                name: selection.team.name,
                alt_name: selection.team.alt_name,
                icon: selection.team.icon
            };
        } else if (showPicks?.pick_3 && !selectedPicks.pick_3) {
            tempSelectedPicks.pick_3 = {
                match_id: selection.match_id,
                team_id: selection.team_id,
                status: "",
                goalserve_id: selection.team.goalserve_id,
                name: selection.team.name,
                alt_name: selection.team.alt_name,
                icon: selection.team.icon
            };
        }
        setSelectedPicks(tempSelectedPicks);
        setSelectionChanged(!selectionChanged);
        setPickNumber(prevState => ++prevState);
        setShowPickModal(true);
    }

    const handleRemovePick = (selection, pickNumber) => {
        const deadline = selectedWeek?.start_date || skinInfo?.game_deadline;
        const nowTime = moment().tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
        // const nowTime = moment('2023-09-30 15:35:00').tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
        const deadlineTime = moment(deadline).format("YYYY-MM-DDTHH:mm:ss");
        let isDeadlineReached = nowTime >= deadlineTime;
        // console.log(deadline, nowTime, deadlineTime, isDeadlineReached);

        if (isDeadlineReached) {
            setShowDeadlineModal(true);
            return false;
        }

        let tempSelectedPicks = selectedPicks;
        if (pickNumber === 1) {
            tempSelectedPicks = {
                pick_1: null,
                pick_2: selectedPicks.pick_2,
                pick_3: selectedPicks.pick_3,
            };
        } else if (pickNumber === 2) {
            tempSelectedPicks = {
                pick_1: selectedPicks.pick_1,
                pick_2: null,
                pick_3: selectedPicks.pick_3,
            };
        } else if (pickNumber === 3) {
            tempSelectedPicks = {
                pick_1: selectedPicks.pick_1,
                pick_2: selectedPicks.pick_2,
                pick_3: null,
            };
        }
        setSelectedPicks(tempSelectedPicks);
        setPickNumber(prevState => --prevState);
        setShowPickModal(pickNumber > 1);
    }

    const handleSetCurrentWeek = (value) => {
        setCurrentWeek(value);
    }

    const handleSetSelectedWeek = (value) => {
        setSelectedWeek(value);
    }

    const fetchSkinInfo = () => {
        let encrypted = false;
        let token = '';
        if (user.token) {
            encrypted = true;
            token = user.token;
        }
        let endpoint = SKIN_SINGLE;
        endpoint = endpoint.replace('__SKIN_ID__', skinId);
        let isStaging = parseInt(params.get('staging'));
        get(endpoint, encrypted, token, dispatch, isStaging ? STAGING_BASE_URL : '')
            .then((response) => {
                setSkinInfo(response.data.data);
                setSkinLoading(false);
            })
            .catch((error) => {
                setSkinLoading(false);
                console.error('get_weeks_error', error);
            });
    }

    useEffect(() => {
        fetchSkinInfo();
    }, [skinId]);

    useEffect(() => {
        // console.log('Matches', currentWeek, selectedWeek, weekId, selectedPicks, selectionChanged, showPickModal, selectionAllowed, isEdit, availablePicks, showDeadlineModal)
    }, [currentWeek, selectedWeek, weekId, selectedPicks, selectionChanged, showPickModal, selectionAllowed, isEdit, availablePicks, showDeadlineModal]);

    useEffect(() => {
        console.log('state', state);
        if (state !== null) {
            if (state.selectedPicks !== null && state.selectedPicks !== undefined) {
                setIsEdit(true);
                setPickToEdit({
                    number: state.pickNumber,
                    selectedPicks: state.selectedPicks,
                });
            } else {
                setIsEdit(false);
            }
            setSelectionAllowed(true);
            setSelectionChanged(!selectionChanged);
        } else {
            setSelectionAllowed(false);
        }
    }, [state]);

    useEffect(() => {
        console.log('pickToEdit', pickToEdit, selectedPicks, isEdit);
        if (pickToEdit !== null && currentWeek) {
            let removePickId = `pick_${pickToEdit.number}`;
            if (selectedPicks) {
                let tempSelected = selectedPicks;
                tempSelected[removePickId] = null;
                setSelectedPicks(tempSelected);
                setSelectionChanged(!selectionChanged);
            }
        }
    }, [pickToEdit, selectedPicks, isEdit]);

    const renderMobile = () => {
        return (
            <div className={'mt-5 mb-5'}>
                <Helmet>
                    <title>Sportskins - Fixtures</title>
                    <meta name="description" content="Sportskins- Fixtures"/>
                </Helmet>
                <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} isSuccess={true}
                            withHeading={true} body={'Picks Selected Successfully'} heading={'Success!'}
                            handleClose={() => {
                                navigation('/my-skins');
                            }}
                />
                <AlertModal showModal={showDeadlineModal} setShowModal={setShowDeadlineModal} isSuccess={false}
                            withHeading={true} body={'Cannot select picks after deadline'} heading={'Deadline Passed!'}
                            handleClose={() => {
                                navigation('/my-skins');
                            }}
                />
                <SelectedPicksModal isMobile={isMobile} showModal={showPickModal} setShowModal={setShowPickModal}
                                    handleRemovePick={handleRemovePick} showPicks={showPicks} skinId={skinId}
                                    weekId={weekId} isEdit={isEdit} pickToEdit={pickToEdit}
                                    selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks}
                                    pickNumber={pickNumber} setPickNumber={setPickNumber}
                                    availablePicks={availablePicks}
                                    showAlertModal={showAlertModal} setShowAlertModal={setShowAlertModal}
                />
                <div className={'container mt-3'}>
                    <SmallLoader loading={skinLoading} className={'text-black'}/>
                    {!skinLoading && <SkinInfo isMobile={isMobile} skin={skinInfo} currentWeek={selectedWeek?.week}/>}
                </div>
                <div className={'w-100 bg-skins-white rounded-my-skins-top pt-5 mt-5 pb-5'}>
                    <div className={'mt-0'}>
                        <WeeksList isMobile={isMobile} skinId={parseInt(skinId)} weekId={parseInt(weekId)}
                                   setCurrentWeek={handleSetCurrentWeek} setSelectedWeek={handleSetSelectedWeek}
                                   setSelectedPicks={setSelectedPicks}/>
                    </div>
                    {selectionAllowed &&
                        <div className={'mt-3'}>
                            <Picks isMobile={isMobile} showPicks={showPicks} selectedPicks={selectedPicks}
                                   selectionChanged={selectionChanged} setShowPickModal={setShowPickModal}
                                   isEdit={isEdit} pickToEdit={pickToEdit} currentWeek={currentWeek}
                            />
                        </div>
                    }
                    <div className={'mt-1'}>
                        <MatchesList isMobile={isMobile} skinId={parseInt(skinId)} weekId={parseInt(weekId)}
                                     currentWeek={currentWeek} setShowPicks={setShowPicks}
                                     selectionAllowed={selectionAllowed} selectionChanged={selectionChanged}
                                     setSelectedPicks={setSelectedPicks} handlePick={handlePick}
                                     setSelectionAllowed={setSelectionAllowed} selectedPicks={selectedPicks}
                                     deadline={selectedWeek?.start_date || skinInfo?.game_deadline}
                                     setAvailablePicks={setAvailablePicks}
                                     isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                                     setPickNumber={setPickNumber}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const renderDesktop = () => {
        return (
            <Container className={'mt-5 mb-5'}>
                <Helmet>
                    <title>Sportskins - Fixtures</title>
                    <meta name="description" content="Sportskins- Fixtures"/>
                </Helmet>
                <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} isSuccess={true}
                            withHeading={true} body={'Picks Selected Successfully'} heading={'Success!'}
                            handleClose={() => {
                                navigation('/my-skins');
                            }}
                />
                <AlertModal showModal={showDeadlineModal} setShowModal={setShowDeadlineModal} isSuccess={false}
                            withHeading={true} body={'Cannot select picks after deadline'} heading={'Deadline Passed!'}
                            handleClose={() => {
                                navigation('/my-skins');
                            }}
                />
                <SelectedPicksModal isMobile={isMobile} showModal={showPickModal} setShowModal={setShowPickModal}
                                    handleRemovePick={handleRemovePick} showPicks={showPicks} skinId={skinId}
                                    weekId={weekId} isEdit={isEdit} pickToEdit={pickToEdit}
                                    selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks}
                                    pickNumber={pickNumber} setPickNumber={setPickNumber}
                                    availablePicks={availablePicks}
                                    showAlertModal={showAlertModal} setShowAlertModal={setShowAlertModal}
                />
                <div className={'mt-3'}>
                    <SmallLoader loading={skinLoading}/>
                    {!skinLoading && <SkinInfo isMobile={isMobile} skin={skinInfo}/>}
                </div>
                <div className={'mt-3'}>
                    <WeeksList isMobile={isMobile} skinId={parseInt(skinId)} weekId={parseInt(weekId)}
                               setCurrentWeek={handleSetCurrentWeek} setSelectedWeek={handleSetSelectedWeek}
                               setSelectedPicks={setSelectedPicks}/>
                </div>
                {selectionAllowed &&
                    <div className={'mt-3'}>
                        <Picks isMobile={isMobile} showPicks={showPicks} selectedPicks={selectedPicks}
                               selectionChanged={selectionChanged} setShowPickModal={setShowPickModal}
                               isEdit={isEdit} pickToEdit={pickToEdit} currentWeek={currentWeek}
                        />
                    </div>
                }
                <div className={'mt-3'}>
                    <MatchesList isMobile={isMobile} skinId={parseInt(skinId)} weekId={parseInt(weekId)}
                                 currentWeek={currentWeek} setShowPicks={setShowPicks}
                                 selectionAllowed={selectionAllowed} selectionChanged={selectionChanged}
                                 setSelectedPicks={setSelectedPicks} handlePick={handlePick}
                                 setSelectionAllowed={setSelectionAllowed} selectedPicks={selectedPicks}
                                 deadline={selectedWeek?.start_date || skinInfo?.game_deadline}
                                 setAvailablePicks={setAvailablePicks}
                                 isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                                 setPickNumber={setPickNumber}
                    />
                </div>
            </Container>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

Matches.propTypes = {
    isMobile: PropTypes.bool.isRequired
}

export default Matches;
