import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const PrivacyPolicy = () => {
    const render = () => {
        return (
            <div className={'w-100'}>
                <Helmet>
                    <title>Sportskins - Privacy Policy</title>
                    <meta name="description" content="Sportskins- Privacy Policy"/>
                </Helmet>
                <Container>
                    <Row>
                        <h1 className={'text-center fw-bold font-48 text-white syne mt-5 mb-5'}>
                            Privacy Policy
                        </h1>
                    </Row>
                </Container>
                <section className={`bg-team-card-grey pt-5 ps-5 pe-5 mt-0 rounded-my-skins-top`}
                         aria-label={'SportSkins Game Rules'}>
                    <Container className={'p-5 mt-0 rounded-my-skins-top'}>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                PRIVACY POLICY
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Your personal information:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We at Community Gaming Ltd (‘CGL’) understand the importance of your privacy and the
                                security of your personal data. This privacy policy explains how we collect and process
                                your personal data. Personal data, or personal information, means any information about
                                an individual from which that person can be identified. This includes information that
                                you tell us, what we learn from you and the choices you make about the marketing you
                                want us to send to you. This policy explains how we do this, what your rights are over
                                any personal information we hold about you and how the law protects you.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                This notice applies to you if you use our services via our website, mobile applications
                                or otherwise by interacting with us on social media platforms. This privacy policy
                                applies to customers and employees and underpins our commitment to protecting your
                                personal information.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                The law in relation to data protection changed on the 25 May 2018 when the General Data
                                Protection Regulation(‘GDPR’) came into force in the United Kingdom and across Europe.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                There may be further changes to GDPR laws which we will be required to make to this
                                privacy policy. If this is the case we will notify you every time we make a change to
                                this policy. We may need to ask you to agree to the changes, or refresh your consent to
                                us using your personal information
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Who we are:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                References in this policy to ‘CGL’, ‘we’ or ‘us’ relate to Community Gaming Ltd. Our
                                registered office is at Coopers House, 65a Wingletye Lane, Hornchurch, Essex RM11 3AT.
                                Company registered number 13156882
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Community Gaming Limited is licensed by the Gambling Commission of Great Britain under
                                account number
                                <a className={'ps-2 pe-2 text-skins-red'}
                                   href={'https://www.gamblingcommission.gov.uk/public-register/business/detail/58494'}
                                   target={'_blank'} rel={'noreferrer'}>
                                    58494
                                </a>
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You can contact us by email at
                                <a href={'mailto:customerservices@communitygaming.co.uk'}
                                   className={'ps-2 pe-2 text-skins-red text-decoration-none'}>
                                    customerservices@communitygaming.co.uk
                                </a>
                                . If you need to you can write to us at our registered office (see above).
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Our representative for all queries in relation to this policy and your data protection
                                rights can be contacted on
                                <a href={'mailto:customerservices@communitygaming.co.uk'}
                                   className={'ps-2 pe-2 text-skins-red text-decoration-none'}>
                                    customerservices@communitygaming.co.uk
                                </a>
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                When we refer to our website, we mean our website at www.sportskins.uk. All references
                                to the site in this policy shall include the site as operated on any device, including
                                computers, tablets and phones, and shall apply equally to the mobile application of the
                                site.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Where we collect your personal information from:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We may collect personal information about you in the following ways:
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Data you give to us:
                            </p>
                        </div>
                        <ul className={"ms-5 me-5"}>
                            <li className={'cabin font-19 fw-500'}>
                                Data you give to us when you register to use our services
                            </li>
                            <li className={'cabin font-19 fw-500'}>
                                When you use our website and/or Mobile Applications
                            </li>
                            <li className={'cabin font-19 fw-500'}>
                                In emails or letters to us
                            </li>
                            <li className={'cabin font-19 fw-500'}>
                                If you take part in our competitions or promotions
                            </li>
                            <li className={'cabin font-19 fw-500'}>
                                When you give us feedback
                            </li>
                            <li className={'cabin font-19 fw-500'}>
                                Social media platforms
                            </li>
                        </ul>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Data we collect when you use our services:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Payment and transaction data
                            </p>
                        </div>
                        <ul className={"ms-5"}>
                            <li className={'cabin font-19 fw-500'}>
                                Profile and usage data, including data we gather from the devices you use to connect to
                                those services such as computers and mobile phones, using cookies (please see cookie
                                policy
                                for details) and other internet tracking software.
                            </li>
                        </ul>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Data from third parties we work with:
                            </strong>
                        </div>
                        <br/>
                        <ul className={"ms-5"}>
                            <li className={'cabin font-19 fw-500'}>
                                Identity verification data from W2 Global Data Solutions Ltd
                            </li>
                            <li className={'cabin font-19 fw-500'}>
                                Government and law enforcement agencies
                            </li>
                        </ul>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Data we collect about you:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We may collect, use, store and transfer different kinds of personal data about you which
                                we have grouped together as follows:
                            </p>
                        </div>
                        <ul className={"ms-5 me-5 cabin font-19 fw-500"}>
                            <li>
                                <strong className={"fw-bold"}>Identity data – </strong>
                                name, username, title, date of birth and gender
                            </li>
                            <li>
                                <strong className={"fw-bold"}>Contact data – </strong>
                                postal address, email address or telephone numbers
                            </li>
                            <li>
                                <strong className={"fw-bold text-nowrap"}>Financial data – </strong>
                                bank account and payment card details (please note,
                                once collected, this financial data is stored and used by our third-party
                                payment provider, Checkout.com)
                            </li>
                            <li>
                                <strong className={"fw-bold text-nowrap"}>Transaction data – </strong>
                                details about payments to and from you and other details of
                                services you have purchased from us

                            </li>
                            <li>
                                <strong className={"fw-bold text-nowrap"}>Technical data – </strong>
                                internet protocol (IP) address, your login data, browser type
                                and version, time zone setting and location, browser plug-in types and versions,
                                operating system and platform and other technology on the devices you use to
                                access our website

                            </li>
                            <li>
                                <strong className={"fw-bold text-nowrap"}>Profile data – </strong>
                                your username and password, transactions made by you, sports teams you follow,
                                your interests, preferences, feedback, and survey responses
                            </li>
                            <li>
                                <strong className={"fw-bold text-nowrap"}>Usage data – </strong>
                                information about how you use our website and Mobile Application services.
                            </li>
                            <li>
                                <strong className={"fw-bold text-nowrap"}>Marketing and communications data
                                    – </strong>
                                We may periodically receive data about you from third party service providers with
                                whom we work closely. These third-party sources include, but are not limited to,
                                advertising and social media networks, analytics providers, and search information
                                providers. We also collect your preferences in relation to receiving marketing
                                from us, selected third parties as well as your general communication preferences.
                            </li>
                        </ul>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We may collect, use, and share aggregated data such as statistical or demographic data
                                for any purpose. Aggregated data may be derived from your personal data but is not
                                considered personal data in law as this data does not directly or indirectly reveal
                                your identity. For example, we may aggregate your usage data to calculate the
                                percentage of users that enter more than one of our competitions at any given time.
                                However, if the aggregated data connects with your personal data so that it can
                                directly or indirectly identify you, we treat the combined data as personal data which
                                will be used in accordance with this privacy notice.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We do not collect any special categories of personal data about you. This includes
                                details about race or ethnicity, religious or philosophical beliefs, sex life, sexual
                                orientation, political opinions, trade union membership, information about your health
                                and genetic and biometric data). Nor do we collect any information about criminal
                                convictions and offences.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                How we use your personal information:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Your privacy is protected by law.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We are only allowed to use personal information about you if we have a legal basis to
                                do so, and we are required to tell you what that legal basis is. We have set out in the
                                table below: the personal information which we collect from you, how we use it, and the
                                legal ground on which we rely when we use the personal information.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                In some circumstances we can use your personal information if it is in our legitimate
                                interest to do so, provided that we have told you what that legitimate interest is.
                                A legitimate interest is when we have a business or commercial reason to use your
                                information which, when balanced against your rights, is justifiable. If we are
                                relying on our legitimate interests, we have set that out in the table below.
                            </p>
                        </div>
                        <div className="table-responsive">
                            <table className={"table align-start table-striped table-hover"}>
                                <thead className={"align-middle"}>
                                <tr className={'cabin font-19 fw-500 text-center'}>
                                    <th className={"bg-skins-red text-white p-3"} scope="col">What we use your personal
                                        information for
                                    </th>
                                    <th className={"bg-skins-red text-white p-3"} scope="col">What personal information
                                        we
                                        collect
                                    </th>
                                    <th className={"bg-skins-red text-white p-3"} scope="col">Our legal grounds for
                                        processing
                                    </th>
                                    <th className={"bg-skins-red text-white p-3"} scope="col">Our legitimate interests
                                        (if
                                        applicable)
                                    </th>
                                </tr>
                                </thead>
                                <tbody className={'cabin font-19 fw-500'}>
                                <tr>
                                    <td>To register you as a new user of our services (including identity
                                        verification)
                                    </td>
                                    <td>Identity Data Contact Data Financial Data Marketing and communications data</td>
                                    <td>Performance of a contract with you. Our legitimate interests. Our legal duty.
                                        Your
                                        consent
                                    </td>
                                    <td>Keeping our records up to date, working out which of our services may interest
                                        you
                                        and telling you about them. Defining types of members for new services.
                                        Seeking your consent when we need it to contact you. Being efficient about how
                                        we
                                        fulfil our legal and contractual duties. To study how members use our services,
                                        to develop them, to promote and grow our business and to inform our marketing
                                        strategy
                                    </td>
                                </tr>
                                <tr>
                                    <td>To allow our third-party payment provider (Checkout.com) to effect transactions
                                        in connection with your use of our services
                                    </td>
                                    <td>Identity Data Contact Data Financial Data</td>
                                    <td>Performance of a contract with you. Our legitimate interests</td>
                                    <td>Being efficient about how we fulfil our legal and contractual duties. Complying
                                        with rules and guidance from regulators
                                    </td>
                                </tr>
                                <tr>
                                    <td>To manage our relationship with you, including notifying you about changes to
                                        our
                                        terms or privacy notices
                                    </td>
                                    <td>Identity data Contact data Transaction data Profile data</td>
                                    <td>Performance of a contract with you. Necessary to comply with a legal
                                        obligation. Our legitimate interests
                                    </td>
                                    <td>To keep our records up to date</td>
                                </tr>
                                <tr>
                                    <td>To administer and protect our business and our website</td>
                                    <td>Transaction data Technical Usage data</td>
                                    <td>Our legitimate interests. Compliance with our legal obligations</td>
                                    <td>Running our business, provision of administration and IT services, network
                                        security
                                    </td>
                                </tr>
                                <tr>
                                    <td>To deliver relevant website content and advertisements to you and measure or
                                        understand the effectiveness of the advertising we serve to you
                                    </td>
                                    <td>Identity data Contact data Marketing and communications data Usage data Profile
                                        data
                                    </td>
                                    <td>Our legitimate interests</td>
                                    <td>To study how members and non-members use our services, to develop them, to grow
                                        our
                                        business and to inform our marketing strategy
                                    </td>
                                </tr>
                                <tr>
                                    <td>To use data analytics to improve our website, products / services, marketing,
                                        customer relationships and experiences
                                    </td>
                                    <td>Usage data</td>
                                    <td>Performance of a contract with you. Our legitimate interests</td>
                                    <td>Being efficient about how we fulfil our legal and contractual duties</td>
                                </tr>
                                <tr>
                                    <td>To make suggestions and recommendations to you about services that may be of
                                        interest to you
                                    </td>
                                    <td>Identity data Contact data Marketing and communications data Usage data Profile
                                        data
                                    </td>
                                    <td>Our legitimate interests</td>
                                    <td>To develop our services and grow our business. To study how members and
                                        non-members
                                        use our services, to develop them, to grow our business and to inform our
                                        marketing
                                        strategy.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Who we share your personal information with:
                            </strong>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We may share your personal information with any of the following organisations, for the
                                purposes of providing the services which you have requested from us:
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Our third-party payment provider, Checkout.com (or such other third-party payment
                                provider appointed by us from time to time) who process your financial data on our
                                behalf in order to facilitate your transactions in relation to our website.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                W2 Global Data Solutions (or such other third-party identity data service provider
                                appointed by us from time to time) for identity verification purposes.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Authorised credit reference agencies.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                HM Revenue & Customs and other regulators or authorities
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Anonymised betting information may be shared with third-party partners.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You can find details of how these third parties use your personal information by
                                looking at their privacy policies, all of which should be available on the relevant
                                websites, or on request.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We require all organisations who we share your data with to respect the security
                                of your personal data and to treat it in accordance with the law. We do not allow any
                                of our service providers to use your personal data for their own purposes and only
                                permit them to process your personal data for specified purposes and in accordance
                                with our instructions.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We may transfer your personal information to a third party as part of a sale of some
                                or all of our business and assets to any third party or as part of any business
                                restructuring or reorganisation.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Failing to provide personal data:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Where we need to collect personal data by law, or under the terms of a contract we
                                have with you and you fail to provide that data when requested, we may not be able to
                                perform the contract we have or are trying to enter into with you (for example, to
                                provide you with services via our website). In this case, we may have to cancel your
                                user account or a service you have with us but we will notify you if this is the case
                                at the time.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Third party links:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Our website may include links to third party websites, plug-ins and applications.
                                Clicking on those links or enabling those connections may allow third parties to
                                collect or share data about you. We do not control these third-party websites and
                                are not responsible for their privacy statements. When you leave our website, we
                                encourage you to read the privacy notice or policy of every website you visit.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Transferring your personal information outside the UK:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                If we transfer your personal information outside the UK we have to tell you. We do not
                                transfer your data outside of the UK.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Data security:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We have put in place appropriate security measures to prevent your personal data from
                                being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.
                                In addition, we limit access to your personal data to those employees, agents,
                                contractors and other third parties who have a business need to know. They will only
                                process your personal data on our instructions, and they are subject to a duty
                                of confidentiality.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We have put in place procedures to deal with any suspected personal data breach and
                                will notify you and any applicable regulator (including the ICO) of a breach
                                where we are legally required to do so.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                How long do we keep your personal information?
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We will keep your personal information for as long as you are our customer or for as
                                long as we reasonably require it for legal or business purposes.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                After you stop being a customer, because you have stopped regularly using our services,
                                we may keep your personal information for up to 7 years for one of the following
                                reasons:
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                To respond to any questions or complaints from you
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                To maintain our records
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                To comply with laws applicable to us
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                To comply with regulations to which we are subject
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Please note that we may be required in certain circumstances to retain your information
                                indefinitely (for example under our procedures on responsible gambling and
                                self-exclusion). We will take all necessary steps to ensure that the privacy of
                                information is maintained for the period of retention.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Marketing:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We may use your personal information to tell you about relevant goods or services
                                and any upcoming offers or special events.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We can only use your personal information to send you marketing messages if we have
                                either your consent or a legitimate interest to do so.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You can ask us to stop sending you marketing messages at any time – you just need to
                                contact us or use the opt-out links on any marketing message sent to you.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We will get your express opt-in consent before we share your personal data with any
                                company other than us for marketing purposes. You can ask a third-party company to stop
                                sending you marketing messages at any time, by adjusting your marketing preferences in
                                relation to that company or by using the opt-out links on any marketing message sent to
                                you.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Where you opt out of receiving marketing messages, this will not apply to personal data
                                provided to us as a result of purchasing our services or any other transaction between
                                you and us.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Most browsers and applications also allow you to control notifications settings. For
                                example, when you first download our application on IOS/android, you will be asked to
                                allow or block notifications from our application. Please refer to your browser/app
                                settings for full details.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Your rights:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You have certain rights which are set out in the law relating to your personal
                                information. The most important rights are set out below.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Getting a copy of the information we hold:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You can ask us for a copy of the personal information which we hold about you, by
                                writing to us FAO Paul Tarantino , Community Gaming Ltd, Coopers House, 65a Wingletye
                                Lane, Hornchurch,Essex RM11 3AT . This is known as a data subject access request.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You will not have to pay a fee to access your personal data, unless we believe that your
                                request is clearly unfounded, repetitive or excessive. In such circumstances we can
                                charge a reasonable fee or refuse to comply with your request.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We will try to respond to all legitimate requests within one month. Occasionally it may
                                take us longer than a month and in that case we will notify you and keep you updated.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Telling us if information we hold is incorrect
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You have the right to question any information we hold about you that you think is wrong
                                or incomplete. Please contact us at
                                <a href={'mailto:customerservices@communitygaming.co.uk'}
                                   className={'ps-2 pe-2 text-skins-red text-decoration-none'}>
                                    customerservices@communitygaming.co.uk
                                </a>
                                if you want to do this and we will take reasonable steps to check its accuracy and, if
                                necessary,
                                correct it.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Telling us if you want us to stop using your personal information:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You have the right to:
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                object to our use of your personal information (known as the right to object); or
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                ask us to delete the personal information (known as the right to erasure); or
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                request the restriction of processing; or
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                ask us to stop using it if there is no need for us to use it (known as the right to be
                                forgotten).
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Please contact us
                                <a href={'mailto:customerservices@communitygaming.co.uk'}
                                   className={'ps-2 pe-2 text-skins-red text-decoration-none'}>
                                    customerservices@communitygaming.co.uk
                                </a>
                                if you want to exercise any of the above rights. There may be legal reasons why we need
                                to keep or use your data, which we will tell you if you exercise one of the above
                                rights.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Where we rely on our legitimate interest:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                In cases where we are processing your personal data on the basis of our legitimate
                                interest, you can ask us to stop for reasons connected to your individual situation. We
                                must then do so unless we believe we have a legitimate overriding reason to continue
                                processing your personal data.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Withdrawing consent:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You can withdraw your consent to us using your personal information at any time. Please
                                contact us at
                                <a href={'mailto:customerservices@communitygaming.co.uk'}
                                   className={'ps-2 pe-2 text-skins-red text-decoration-none'}>
                                    customerservices@communitygaming.co.uk
                                </a>
                                if you want to withdraw your
                                consent. If you withdraw your consent, we may not be able to provide you with certain
                                products or services.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Request a transfer of data:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You may ask us to transfer your personal information to a third party. This right only
                                applies to automated information which you initially provided consent for us to use or
                                where we used the information to perform a contract with you.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Making a complaint:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                Please let us know if you are unhappy with how we have used your personal information by
                                contacting us at
                                <a href={'mailto:customerservices@communitygaming.co.uk'}
                                   className={'ps-2 pe-2 text-skins-red text-decoration-none'}>
                                    customerservices@communitygaming.co.uk
                                </a>
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                You also have a right to complain to the Information Commissioner’s Office. You can find
                                their contact details at
                                <a className={'ps-2 text-skins-red text-decoration-none'}
                                   href={'https://www.ico.org.uk'}
                                   target={'_blank'} rel={'noreferrer'}>
                                    www.ico.org.uk
                                </a>
                                . We would be grateful for the chance to deal
                                with your concerns before you approach the ICO so please contact us in the first
                                instance.
                            </p>
                        </div>
                        <br/>

                        <div>
                            <strong className={'cabin fw-bold font-25'}>
                                Changes to our Privacy Policy:
                            </strong>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We may update this policy from time to time, so please review it regularly.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                If there are material changes made to this Privacy Policy, we will use reasonable
                                endeavours to inform you in advance of the change(s) being implemented. This will be via
                                email or by notice on the website/mobile application and giving an appropriate amount of
                                time for you to consider and understand the changes.
                            </p>
                        </div>
                        <div>
                            <p className={'cabin font-19 fw-500'}>
                                We will not enforce material changes to the Privacy Policy without your express consent.
                                If you decline or otherwise do not accept the changes within the appropriate time
                                period, we may not be able to continue to provide some or all of our products and
                                services.
                            </p>
                        </div>
                    </Container>
                </section>
            </div>
        );
    }

    return render();
}

export default PrivacyPolicy;
