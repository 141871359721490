import PropTypes from "prop-types";
import {ButtonGroup, Row, ToggleButton} from "react-bootstrap";
import {useEffect, useState} from "react";

const TypesList = (props) => {
    const {isMobile, handleTypeClick, selectionChanged, selectedTypeId} = props;

    const [radioValue, setRadioValue] = useState(selectedTypeId || 0);

    useEffect(() => {
    }, [radioValue]);
    useEffect(() => {
        setRadioValue(selectedTypeId);
    }, [selectedTypeId]);

    useEffect(() => {
        setRadioValue(selectedTypeId || 0);
    }, [selectionChanged]);

    const handleClick = (e) => {
        setRadioValue(parseInt(e.target.dataset.id));
        handleTypeClick(e);
    }

    const renderMobile = () => {
        return (
            <Row>
                <ButtonGroup className="mb-2 w-fit">
                    <ToggleButton data-id={1} onClick={handleClick}
                                  type={'radio'} name={'typeToggle'} variant={'outline-dark'}
                                  checked={radioValue === 1}
                                  className={`rounded-my-skins ps-3 pe-3 pt-2 pb-2 roboto font-16 text-uppercase w-fit me-2`}
                                  value={1}>
                        Pre Match
                    </ToggleButton>
                    <ToggleButton data-id={2} onClick={handleClick}
                                  type={'radio'} name={'typeToggle'} variant={'outline-dark'}
                                  checked={radioValue === 2}
                                  className={`rounded-my-skins ps-3 pe-3 pt-2 pb-2 roboto font-16 text-uppercase w-fit ms-2`}
                                  value={2}>
                        Post Match
                    </ToggleButton>
                </ButtonGroup>
            </Row>
        );
    }

    const renderDesktop = () => {
        return (
            <Row>
                <ButtonGroup className="mb-2 w-fit">
                    <ToggleButton data-id={1} onClick={handleClick}
                                  type={'radio'} name={'typeToggle'} variant={'outline-light'}
                                  checked={radioValue === 1}
                                  className={`rounded-my-skins ps-5 pe-5 pt-3 pb-3 roboto font-16 text-uppercase w-fit me-2`}
                                  value={1}>
                        Pre Match
                    </ToggleButton>
                    <ToggleButton data-id={2} onClick={handleClick}
                                  type={'radio'} name={'typeToggle'} variant={'outline-light'}
                                  checked={radioValue === 2}
                                  className={`rounded-my-skins ps-5 pe-5 pt-3 pb-3 roboto font-16 text-uppercase w-fit ms-2`}
                                  value={2}>
                        Post Match
                    </ToggleButton>
                </ButtonGroup>
            </Row>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

TypesList.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    handleTypeClick:
    PropTypes.func.isRequired,
}

export default TypesList;
