export const NAVBAR_ITEMS = [
    {
        'route': '/',
        'name': 'Home',
    },
    {
        'route': '/how-to-play',
        'name': 'How to Play',
    },
    {
        'route': '/game-rules',
        'name': 'Games Rules',
    },
    {
        'route': '/my-skins',
        'name': 'My Skins',
    },
    {
        'route': '/blog',
        'name': 'Blog',
    },
];
