import React, {useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Link, useLocation} from "react-router-dom";
import {NAVBAR_ITEMS} from "../Constants/Navbar";
import {Button} from "react-bootstrap";
import LoginModal from "./LoginModal";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../redux/Actions/LoginAction";

const SkinsNav = (props) => {

    const {isMobile} = props;

    const location = useLocation();
    const isLogin = useSelector(state => state.Login.isLogin);
    const dispatch = useDispatch();

    const [navShowClass, setNavShowClass] = useState('d-none d-md-block');
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

    const handleToggle = (expanded) => {
        if (expanded) {
            setNavShowClass('');
        } else {
            setNavShowClass('d-none d-md-block');
        }
    }

    const render = () => {
        let expand = 'lg';
        return (
            <Navbar expand={expand} className="container-fluid bg-body-tertiary p-3 navbar-bg" bg="dark"
                    data-bs-theme="dark"
                    collapseOnSelect={true}
                    onToggle={handleToggle}
                    sticky={'top'}>
                <Container>
                    <Navbar.Brand href="/" className={`${isMobile ? 'w-60 text-end' : ''}`}>
                        <img
                            src={require('../assets/images/Navbar-Logo.png')}
                            className="d-inline-block align-top"
                            alt="Sport Skins"
                            height={isMobile ? 75 : 135}
                        />
                    </Navbar.Brand>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                        responsive={'lg'}
                        className={`${navShowClass} ${isMobile ? 'bg-dark-blue' : ''}`}
                    >
                        <Offcanvas.Header closeButton closeVariant={'white'}>
                            <Navbar.Brand href="#">
                                <img
                                    src={require('../assets/images/Navbar-Logo.png')}
                                    className="d-inline-block align-top"
                                    alt="Sport Skins"
                                />
                            </Navbar.Brand>
                            {/*<Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Sport Skins
                            </Offcanvas.Title>*/}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3" variant="underline">
                                {
                                    NAVBAR_ITEMS.map((item, index) => (
                                            <Nav.Item
                                                key={`skins_nav_item_${index}`}>
                                                <Nav.Link
                                                    eventKey={`skins_nav_item_${index}`}
                                                    className={`${isMobile && 'text-white border-bottom border-secondary pb-4'} align-items-center d-flex flex-column justify-content-center me-4 ms-4 nav-item nav-link text-uppercase ${item.route === location.pathname ? 'active' : ''}`}
                                                    as={Link}
                                                    to={item.route}>
                                                    {item.name}
                                                </Nav.Link>
                                            </Nav.Item>
                                        )
                                    )
                                }
                                <Nav.Item eventKey={`skins_nav_item_login`} key={`skins_nav_item_login`}
                                          className={`ms-4 me-4 align-items-center d-flex flex-column justify-content-center text-uppercase ${isMobile && 'text-white border-bottom border-secondary pb-4'}`}>
                                    {
                                        !isLogin ?
                                            (
                                                <Button className={'btn btn-sm btn-block btn-skins-red text-uppercase'}
                                                        onClick={() => {
                                                            setShowLoginModal(true)
                                                        }}
                                                >
                                                    Login
                                                </Button>
                                            ) :
                                            (
                                                <Link to={''}
                                                      className={`align-items-center d-flex flex-column justify-content-center me-4 ms-4 nav-item nav-link text-uppercase`}
                                                      onClick={() => {
                                                          dispatch(logout());
                                                      }}
                                                >
                                                    Logout
                                                </Link>
                                            )
                                    }
                                </Nav.Item>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                    <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal}
                                showForgotPasswordModal={showForgotPasswordModal}
                                setShowForgotPasswordModal={setShowForgotPasswordModal}/>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className={'float-end'}/>
                </Container>
            </Navbar>
        );
    }

    return render();
}

export default SkinsNav;
