import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretRight, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const WinningLosing = (props) => {
    const {showWinning, isMobile, showLosing, losing, winning, showMatchesButton, skinId, weekId} = props;

    const renderMobile = () => {
        return (
            <Row className={'text-start'}>
                <Col>
                    <div className={'align-self-top'}>
                        {
                            showWinning &&
                            (
                                <div className={'d-flex'}>
                                    <FontAwesomeIcon icon={faCaretUp} className={'text-winning me-1 align-self-center'}
                                                     size={'1x'}/>
                                    <span className={'text-winning font-24 fw-bold roboto align-self-center'}>
                                        {Intl.NumberFormat("en-GB", {maximumFractionDigits: 0}).format(winning)}
                                    </span>
                                    <span className={'text-winning font-12 roboto ms-1 align-self-center'}>
                                        Winning
                                    </span>
                                </div>
                            )
                        }
                        {
                            showLosing &&
                            (
                                <div className={'d-flex'}>
                                    <FontAwesomeIcon icon={faCaretDown} className={'text-losing me-1 align-self-center'}
                                                     size={'1x'}/>
                                    <span className={'text-losing font-20 fw-bold roboto align-self-center'}>
                                        {Intl.NumberFormat("en-GB", {maximumFractionDigits: 0}).format(losing)}
                                    </span>
                                    <span className={'text-losing font-10 roboto ms-1 align-self-center'}>
                                        Losing
                                    </span>
                                </div>
                            )
                        }
                    </div>
                </Col>
                {
                    showMatchesButton &&
                    (
                        <Col className={'justify-content-end text-end align-self-center d-flex'}>
                            <Link
                                to={`/matches/${skinId}/${weekId}`}
                                className={'btn bg-transparent border-medium-white text-white p-3 rounded-pill w-fit ps-5 pe-5 text-center justify-content-end d-flex align-items-center'}
                            >
                                <span className={'roboto text-capitalize font-25 fw-bold'}>
                                    Matches
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCaretRight} size={'2x'} className={'ms-3'}/>
                            </Link>
                        </Col>
                    )
                }
            </Row>
        );
    }

    const renderDesktop = () => {
        return (
            <Row className={'mt-3'}>
                <Col>
                    <div className={'align-self-center'}>
                        {
                            showWinning &&
                            (
                                <div>
                                    <FontAwesomeIcon icon={faCaretUp} className={'text-winning me-2'} size={'2x'}/>
                                    <span className={'text-winning font-27 fw-bold roboto'}>
                                        {Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 0
                                        }).format(winning)}
                                    </span>
                                    <span className={'text-winning font-20 roboto ms-2'}>
                                        Winning
                                    </span>
                                </div>
                            )
                        }
                        {
                            showLosing &&
                            (
                                <div>
                                    <FontAwesomeIcon icon={faCaretDown} className={'text-losing me-2'} size={'2x'}/>
                                    <span className={'text-losing font-27 fw-bold roboto'}>
                                        {Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 0
                                        }).format(losing)}
                                    </span>
                                    <span className={'text-losing font-20 roboto ms-2'}>
                                        Losing
                                    </span>
                                </div>
                            )
                        }
                    </div>
                </Col>
                {
                    showMatchesButton &&
                    (
                        <Col className={'justify-content-end text-end align-self-center d-flex'}>
                            <Link
                                to={`/matches/${skinId}/${weekId}`}
                                className={'btn bg-transparent border-medium-white text-white p-3 rounded-pill w-fit ps-5 pe-5 text-center justify-content-end d-flex align-items-center'}>
                                <span className={'roboto text-capitalize font-25 fw-bold'}>
                                    Matches
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCaretRight} size={'2x'} className={'ms-3'}/>
                            </Link>
                        </Col>
                    )
                }
            </Row>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

WinningLosing.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    showWinning: PropTypes.bool.isRequired,
    winning: PropTypes.number.isRequired,
    showLosing: PropTypes.bool.isRequired,
    losing: PropTypes.number.isRequired,
    showMatchesButton: PropTypes.bool,
    skinId: PropTypes.number.isRequired,
    weekId: PropTypes.number.isRequired,
}

export default WinningLosing;
