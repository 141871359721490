import PropTypes from "prop-types";

const LeaguePill = (props) => {
    const {isMobile, icon, name} = props;
    const renderMobile = () => {
        return (
            <div className={'bg-medium-white pb-1 rounded-pill ps-2 pe-2 justify-content-center text-center'}>
                <img
                    src={icon}
                    alt={'league icon'} height={15}/>
                <span className={'roboto text-capitalize font-10 fw-bold ms-2'}>
                    {name}
                </span>
            </div>
        );
    }
    const renderDesktop = () => {
        return (
            <div className={'bg-medium-white p-2 rounded-pill w-fit ps-3 pe-5'}>
                <img
                    src={icon}
                    alt={'league icon'} height={30}/>
                <span className={'roboto text-capitalize font-13 fw-bold ms-2'}>
                    {name}
                </span>
            </div>
        );
    }
    const render = () => {
        return isMobile ? renderMobile() : renderDesktop();
    }

    return render();
}

LeaguePill.propTypes = {
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
}

export default LeaguePill;
