import PropTypes from "prop-types";

const SkinName = (props) => {

    const {isMobile, text, fontSize} = props;

    const renderMobile = () => {
        return (
            <span className={'text-white bebas fw-bold font-22 text-uppercase'}>
                {text}
            </span>
        );
    }

    const renderDesktop = () => {
        return (
            <div className={`text-white gibson fw-bold ${fontSize} text-uppercase text-nowrap`}>
                {text}
            </div>
        );
    }
    const render = () => {
        if (isMobile) {
            return renderMobile();
        }
        return renderDesktop();
    }

    return render();
}

SkinName.propTypes = {
    text: PropTypes.string.isRequired,
    fontSize: PropTypes.string,
    isMobile: PropTypes.bool,
}

SkinName.defaultProps = {
    fontSize: 'font-43',
    isMobile: false,
}

export default SkinName;
