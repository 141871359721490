import SkinsRoutes from "./Routes/SkinsRoutes";
import './assets/styles/sass/fonts.module.scss';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/skins.css';
import './assets/styles/fonts.css';
import './assets/styles/animations.css';

function App() {

    return <SkinsRoutes/>
}

export default App;
