import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";

export const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className={'btn btn-lg rounded-my-skins ps-4 pe-4 pt-3 pb-3 roboto text-uppercase border font-16'}
    >
        {children}
    </button>
));
