import PropTypes from "prop-types";
import {Button, Col, Row} from "react-bootstrap";
import {MATCH_STATES} from "../../../Constants/Match";
import moment from "moment-timezone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {MY_SKIN_PICK_MATCH_SCHEMA} from "../../../Constants/Schemas/MySkinSchema";
import {useNavigate} from "react-router-dom";

const PickCard = (props) => {
    let {
        isMobile,
        backgroundColor,
        color,
        match,
        number,
        selected_team,
        inPlay,
        result,
        history,
        skinId,
        weekId,
        selectedPicks
    } = props;

    const navigation = useNavigate();

    const renderDateTime = () => {
        return (
            <Row>
                <Col>
                    <div className={'text-capitalize text-center text-skins-light-medium-gray roboto font-12'}>
                        {moment(`${match.date}`).format('ddd')}
                    </div>
                    <div className={'text-capitalize text-center roboto font-16'}>
                        {match.time}
                    </div>
                </Col>
            </Row>
        );
    }

    const renderTimeScore = (resultIcon, matchScore) => {
        if (isMobile) {
            return renderTimeScoreMobile(resultIcon, matchScore);
        }
        return (
            <Row>
                <div className={'col-8'}>
                    <div className={'text-capitalize text-skins-light-medium-gray roboto font-12'}>
                        {match.status}
                    </div>
                    <div className={'text-capitalize roboto font-16'}>
                        {matchScore}
                    </div>
                </div>
                <div className={'col-2 align-self-center'}>
                    {resultIcon}
                </div>
            </Row>
        );
    }

    const renderTimeScoreMobile = (resultIcon, matchScore) => {
        return (
            <Row className={'justify-content-end pe-3'}>
                <div className={'col-8 w-fit'}>
                    <div className={'text-capitalize text-center roboto font-16 fw-bold'}>
                        {matchScore}
                    </div>
                    <div className={'text-capitalize text-center text-skins-light-medium-gray roboto font-12'}>
                        {match.status}
                    </div>
                </div>
                <div className={'col-2 align-self-top'}>
                    {resultIcon}
                </div>
            </Row>
        );
    }

    const renderMobile = (teamIcon, selectedTeamName, opponentTeamName, resultIcon, matchScore) => {
        let buttonElement = (
            <Button onClick={(e) => {
                e.stopPropagation();
                (!inPlay && !result) ? navigation(`/matches/${skinId}/${weekId}`, {
                    state: {
                        selectedPicks: selectedPicks,
                        pickNumber: number
                    }
                }) : navigation(`/matches/${skinId}/${weekId}`)
            }}
                    state={{selectedPicks: selectedPicks, pickNumber: number}}
                    style={{backgroundColor: backgroundColor, zIndex: 1000}}
                    className={'btn btn-dark rounded-my-skins roboto font-16 ps-5 pe-5 text-uppercase'}>Edit</Button>
        );
        if (inPlay || result) {
            if (MATCH_STATES.UPCOMING.includes(match.state)) {
                buttonElement = renderDateTime();
            }
            if ([...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state)) {
                buttonElement = renderTimeScore(resultIcon, matchScore);
            }
        }
        if (history) {
            buttonElement = <Button className={'btn btn-dark bg-skins-red rounded-my-skins roboto font-16 ps-5 pe-5 text-uppercase'}>{selectedPicks.points}</Button>;
        }
        return (
            <Row className={'mt-3'}>
                <Col className={'text-white roboto font-12 ps-2 col-1 align-self-center'}>
                    {number}
                </Col>
                <Col className={'text-white text-center col-2 align-self-center'}>
                    <img src={teamIcon} alt={'team icon'} height={33}/>
                </Col>
                <Col className={'text-white text-start align-self-center'}>
                    <div className={'fw-bold roboto font-16'}>
                        {selectedTeamName}
                        {
                            history &&
                            <span className={'ps-2'}>{resultIcon}</span>
                        }
                    </div>
                    {
                        !history &&
                        <div className={'roboto font-12'}>
                            vs {opponentTeamName}
                        </div>
                    }
                </Col>
                <Col className={'text-white text-end roboto align-self-center'}>
                    {buttonElement}
                </Col>
            </Row>
        );
    }
    const renderDesktop = (teamIcon, selectedTeamName, opponentTeamName, resultIcon, matchScore) => {
        let buttonElement = 'Edit';
        if (inPlay || result) {
            if (MATCH_STATES.UPCOMING.includes(match.state)) {
                buttonElement = renderDateTime();
            }
            if ([...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state)) {
                buttonElement = renderTimeScore(resultIcon, matchScore);
            }
        }
        return (
            <div style={{backgroundColor: backgroundColor}} className={'rounded-my-skins p-3'}>
                <div style={{color: color}} className={'roboto font-18 fw-bold ps-2'}>
                    {number}
                </div>
                <div style={{color: color}} className={'text-center'}>
                    <img src={teamIcon} alt={'team icon'} height={75}/>
                </div>
                <div style={{color: color}} className={'text-center fw-bold roboto font-19 mt-3'}>
                    {selectedTeamName}
                </div>
                <div style={{color: color}} className={'text-center roboto font-15'}>
                    vs {opponentTeamName}
                </div>
                <div style={{color: color}} className={'text-center roboto mt-3'}>
                    <Button
                        onClick={() => {
                            (!inPlay && !result) ? navigation(`/matches/${skinId}/${weekId}`, {
                                state: {
                                    selectedPicks: selectedPicks,
                                    pickNumber: number
                                }
                            }) : navigation(`/matches/${skinId}/${weekId}`)
                        }}
                        className={'btn btn-dark bg-black rounded-my-skins roboto font-16 ps-5 pe-5 text-uppercase'}>
                        {buttonElement}
                    </Button>
                </div>
            </div>
        );
    }
    const render = () => {

        let teamIcon;
        let selectedTeamName;
        let opponentTeamName;
        // let isWinning = 0;
        let resultIcon = <></>;

        let scores = match?.score?.split(' - ') || [];
        let scoreLocal = parseInt(scores[0] || 0);
        let scoreVisitor = parseInt(scores[1] || 0);
        let matchScore;
        console.log('match', match);

        if (match) {
            if (selected_team === match.local_team.goalserve_id) {
                teamIcon = match.local_team.icon;
                selectedTeamName = match.local_team.alt_name;
                opponentTeamName = match.visitor_team.alt_name;
                matchScore = `${scoreLocal} - ${scoreVisitor}`;

                if ([...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state)) {
                    if (scoreLocal > scoreVisitor) {
                        // isWinning = 1;
                        backgroundColor = '#0fb958';
                        color = '#000000';
                        resultIcon =
                            <FontAwesomeIcon icon={faCheckCircle} size={isMobile ? '1x' : '2x'} color={'#0fb958'}/>;
                    } else {
                        // isWinning = 2;
                        backgroundColor = '#dc1422';
                        color = '#ffffff';
                        resultIcon =
                            <FontAwesomeIcon icon={faTimesCircle} size={isMobile ? '1x' : '2x'} color={'#dc1422'}/>;
                    }
                }
            } else {
                teamIcon = match.visitor_team.icon;
                selectedTeamName = match.visitor_team.alt_name;
                opponentTeamName = match.local_team.alt_name;
                matchScore = `${scoreVisitor} - ${scoreLocal}`;

                if ([...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state)) {
                    if (scoreVisitor > scoreLocal) {
                        // isWinning = 1;
                        backgroundColor = '#0fb958';
                        color = '#000000';
                        resultIcon =
                            <FontAwesomeIcon icon={faCheckCircle} size={isMobile ? '1x' : '2x'} color={'#0fb958'}/>;
                    } else {
                        // isWinning = 2;
                        backgroundColor = '#dc1422';
                        color = '#ffffff';
                        resultIcon =
                            <FontAwesomeIcon icon={faTimesCircle} size={isMobile ? '1x' : '2x'} color={'#dc1422'}/>;
                    }
                }
            }
        }

        if (history) {
            teamIcon = selectedPicks.icon;
            selectedTeamName = selectedPicks.week;
            if (selectedPicks.winner) {
                backgroundColor = '#0fb958';
                color = '#000000';
                resultIcon =
                    <FontAwesomeIcon icon={faCheckCircle} size={isMobile ? '1x' : '2x'} color={'#0fb958'}/>;
            } else {
                backgroundColor = '#dc1422';
                color = '#ffffff';
                resultIcon =
                    <FontAwesomeIcon icon={faTimesCircle} size={isMobile ? '1x' : '2x'} color={'#dc1422'}/>;
            }
        }

        if (isMobile) {
            return renderMobile(teamIcon, selectedTeamName, opponentTeamName, resultIcon, matchScore);
        }

        return renderDesktop(teamIcon, selectedTeamName, opponentTeamName, resultIcon, matchScore);
    }

    return render();

}

PickCard.propTypes = {
    inPlay: PropTypes.bool.isRequired,
    result: PropTypes.bool.isRequired,
    history: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    number: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    selected_team: PropTypes.number,
    match: PropTypes.shape(MY_SKIN_PICK_MATCH_SCHEMA),
    skinId: PropTypes.number.isRequired,
}

export default PickCard;
