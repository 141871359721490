import PropTypes from "prop-types";
import HowToPlayComponent from "../Components/HowToPlayComponent";
import {Helmet} from "react-helmet";

const HowToPlay = (props) => {

    const {isMobile} = props;
    /*const [showCampaignModal, setShowCampaignModal] = useState(true);*/
    const render = () => {
        return (
            <div>
                {/*<CampaignModal setShowModal={setShowCampaignModal} showModal={showCampaignModal}/>*/}
                <section className={`bg-white p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 mt-0 rounded-my-skins-top`}>
                    <Helmet>
                        <title>Sportskins - How To Play</title>
                        <meta name="description" content="Sportskins- How To Play"/>
                    </Helmet>
                    <HowToPlayComponent isMobile={isMobile}/>
                </section>
            </div>
        );
    };

    return render();
}


HowToPlay.propTypes = {
    isMobile: PropTypes.bool.isRequired
};
export default HowToPlay;
