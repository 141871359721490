import PropTypes from "prop-types";
import moment from "moment-timezone";
import {useEffect, useState} from "react";

const TimeComponent = (props) => {

    const {isMobile, datetime, backgroundColor, color} = props;

    const [intervalId, setIntervalId] = useState(null);
    const [, setDate] = useState(null);

    useEffect(() => {
        const id = setInterval(() => {
            setDate(new Date().toLocaleString());
        }, 1000);
        setIntervalId(id);
        return () => clearInterval(intervalId);
    }, []);

    const renderDesktop = (days, hrs, mins, secs) => {

        return (
            <div className={'mt-4 p-2 justify-content-center d-inline-flex'}>
                <div className={'border-0 rounded-4 justify-content-center text-center w-fit pt-2 pb-2 ps-2 pe-2 me-2'}
                     style={{
                         backgroundColor: backgroundColor,
                         color: color,
                     }}
                >
                    <div className={'bebas font-28 fw-bold'}>
                        {days}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Days
                    </div>
                </div>
                <div className={'border-0 rounded-4 justify-content-center text-center w-fit pt-2 pb-2 ps-2 pe-2 me-2'}
                     style={{
                         backgroundColor: backgroundColor,
                         color: color,
                     }}
                >
                    <div className={'bebas font-28 fw-bold'}>
                        {hrs}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Hours
                    </div>
                </div>
                <div className={'border-0 rounded-4 justify-content-center text-center w-fit pt-2 pb-2 ps-2 pe-2 me-2'}
                     style={{
                         backgroundColor: backgroundColor,
                         color: color,
                     }}
                >
                    <div className={'bebas font-28 fw-bold'}>
                        {mins}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Mins
                    </div>
                </div>
                <div className={'border-0 rounded-4 justify-content-center text-center w-fit pt-2 pb-2 ps-2 pe-2 me-2'}
                     style={{
                         backgroundColor: backgroundColor,
                         color: color,
                     }}
                >
                    <div className={'bebas font-28 fw-bold'}>
                        {secs}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Secs
                    </div>
                </div>
            </div>
        );
    }

    const renderMobile = (days, hrs, mins, secs) => {

        return (
            <div className={'ps-4 pe-4 justify-content-center d-inline-flex border-0'} style={{
                backgroundColor: backgroundColor,
                color: color,
                borderBottomLeftRadius: '35px',
                borderTopRightRadius: '35px',
                position: 'absolute',
                left: 'auto',
                right: '8px',
                marginTop: '-16px'
            }}>
                <div className={'border-0 justify-content-center text-center w-fit pt-1 pb-1 ps-1 pe-1 me-1'}>
                    <div className={'bebas font-21 fw-bold'}>
                        {days}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Days
                    </div>
                </div>
                <div className={'border-0 justify-content-center text-center w-fit pt-1 pb-1 ps-1 pe-1 me-1'}>
                    <div className={'bebas font-21 fw-bold'}>
                        {hrs}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Hours
                    </div>
                </div>
                <div className={'border-0 justify-content-center text-center w-fit pt-1 pb-1 ps-1 pe-1 me-1'}>
                    <div className={'bebas font-21 fw-bold'}>
                        {mins}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Mins
                    </div>
                </div>
                <div className={'border-0 justify-content-center text-center w-fit pt-1 pb-1 ps-1 pe-1 me-1'}>
                    <div className={'bebas font-21 fw-bold'}>
                        {secs}
                    </div>
                    <div className={'sfProDisplay font-8 text-upper-case'}>
                        Secs
                    </div>
                </div>
            </div>
        );
    }

    const render = () => {
        const todayDate = moment().tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
        const endDate = moment(datetime).tz("Europe/London").utc().local().format("YYYY-MM-DDTHH:mm:ss");
        const diffTime = moment(endDate).diff(todayDate);
        const duration = moment.duration(diffTime);
        const /*years = duration.years().toLocaleString(),*/
            days = String(duration.days().toLocaleString()).padStart(2, "0"),
            months = duration.months().toLocaleString(),
            hrs = String(duration.hours().toLocaleString()).padStart(2, "0"),
            mins = String(duration.minutes().toLocaleString()).padStart(2, "0"),
            secs = String(duration.seconds().toLocaleString()).padStart(2, "0");
        /*const deadlineReached = (parseInt(months, 10) + parseInt(days, 10) + parseInt(hrs, 10) + parseInt(mins, 10) + parseInt(secs, 10)) <= 0;*/
        if (isMobile) {
            return renderMobile(days, hrs, mins, secs);
        }
        return renderDesktop(days, hrs, mins, secs);
    }

    return render();
}

TimeComponent.propTypes = {
    datetime: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
}

export default TimeComponent;
