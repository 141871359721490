export const HomeSection4Images = [
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-243-1.png"),
        "alt": "Sportskins Home Up Next Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-242-1.png"),
        "alt": "Sportskins Home Login Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-260-1.png"),
        "alt": "Sportskins Home Games Screen"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-240-1.png"),
        "alt": "Sportskins Home My Open Skins Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-241-1.png"),
        "alt": "Sportskins Home Games Screen 2 Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-246-1.png"),
        "alt": "Sportskins Home How to Play Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-244-1.png"),
        "alt": "Sportskins Home My Skins 2 Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-245-1.png"),
        "alt": "Sportskins Home Profile Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-243-1.png"),
        "alt": "Sportskins Home Up Next Image"
    },
    {
        "src": require("../images/wp-content/uploads/2023/03/Component-242-1.png"),
        "alt": "Sportskins Home Login Image"
    }
];
