import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const ResponsibleGambling = () => {

    const render = () => {
        return (
            <div className={'w-100'}>
                <Helmet>
                    <title>Sportskins - Responsible Gambling</title>
                    <meta name="description" content="Sportskins- Responsible Gambling"/>
                </Helmet>
                <Container>
                    <Row>
                        <h1 className={'text-center fw-bold font-48 text-white syne mt-5 mb-5'}>
                            Responsible Gambling
                        </h1>
                    </Row>
                </Container>
                <section className={`bg-team-card-grey pt-5 ps-5 pe-5 mt-0 rounded-my-skins-top`}
                         aria-label={'SportSkins Game Rules'}>
                    <Container className={'p-5 mt-0 rounded-my-skins-top'}>
                        <p><strong>Age Verification:</strong></p>
                        <p><span className={'fw-400 cabin'}>You must be over 18 years of age to register or play Premskins, Champskins or any other games that the Sportskins App/Community Gaming Limited provides.&nbsp; It is an offence for anyone under the age of 18 to attempt to enter a game or register, in an attempt to enter a game.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>&lsquo;Sportskins&rsquo; has sophisticated software and procedures in place to minimise the risk of underage customers entering games. If we have any doubt about the legality of any customer attempting to register or enter a game, we will conduct further age verification checks and may require documentary evidence to confirm the age of the applicant. During this period of age verification, no winnings will be paid to any customer.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>If you suspect that someone under the age of 18 is gambling with &lsquo;Sportskins&rsquo; please email: </span><span
                            className={'fw-400 cabin'}>customerservices@communitygaming.co.uk</span><span
                            className={'fw-400 cabin'}> </span><span className={'fw-400 cabin'}>to report this.</span></p>
                        <p><strong>Youth Access</strong></p>
                        <p><span className={'fw-400 cabin'}>Youth Access is the UK&rsquo;s national membership organisation and directory for young people's information, advice, counselling and support services. Further information can be found by visiting their website </span><a
                            href="http://www.youthaccess.org.uk/"><span
                            className={'fw-400 cabin'}>www.youthaccess.org.uk</span></a><span
                            className={'fw-400 cabin'}> </span><span className={'fw-400 cabin'}>or by calling 0208 772 9900.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>Keep in control:</strong></p>
                        <p><span className={'fw-400 cabin'}>We at Community Gaming Ltd believe that gambling should always be an enjoyable leisure activity. Remembering these simple tips can help make sure your gambling doesn&rsquo;t become a problem.</span>
                        </p>
                        <ol>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Gambling should be entertaining and not seen as a way of making money.</span>
                            </li>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Always bet sensibly and never chase your losses.</span>
                            </li>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Only gamble what you can afford to lose. At CGL have maximum entry cost of &pound;30 per competition to set deposit limits.</span>
                            </li>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Monitor the amount of time you spend playing.</span>
                            </li>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Balance gambling with other activities. If gambling is your only form of entertainment think about whether you are still having fun.</span>
                            </li>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Take regular breaks from gambling. Gambling continuously can cause you to lose track of time and perspective. You can take a time out, a short break that ranges from 24 hours to six weeks.</span>
                            </li>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Don&rsquo;t gamble when under the influence of alcohol or when you&rsquo;re upset or depressed.</span>
                            </li>
                            <li className={'fw-400 cabin'} aria-level="1"><span className={'fw-400 cabin'}>Think about how much money you spend gambling.&nbsp;&nbsp;</span>
                            </li>
                        </ol>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>Take a break</strong></p>
                        <p><strong>Time-Out</strong></p>
                        <p><span className={'fw-400 cabin'}>A Time Out is an account option where you can take a short break from gambling. You can choose to apply a &lsquo;CGL Gaming Break&rsquo; for a period of 24 hours, 7 days or 1 month.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>Once a Time Out has been applied to your account, you will be unable to gamble using your CGL account. Once you have chosen the time period that best suits your needs, we will not be able to reactivate your account until that time period has elapsed.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>If you feel you are at risk of developing a gambling problem or believe you currently have a gambling problem, you should consider using the self-exclusion option</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>To take a Time-Out please contact</span><span
                            className={'fw-400 cabin'}> </span><span
                            className={'fw-400 cabin'}>customerservices@communitygaming.co.uk</span><span
                            className={'fw-400 cabin'}>.</span></p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>Self-Exclusion</strong></p>
                        <p><span className={'fw-400 cabin'}>If you think you are spending too much time or money gambling, or if you feel that you are at risk of developing a gambling problem or believe you currently have a gambling problem, you should then consider Self-Exclusion.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>If you want to stop playing for any other reason, please consider taking a CGL Gaming Break or request an account closure.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>Self-Exclusion enables you to restrict access to your account for a set period of time. You have the option to select a period of time that best suits your needs, ranging from the minimum term of 6 months, up to a maximum of five years. While there is an active Self-Exclusion agreement in place on your account, you will be unable to place any bets nor will you be allowed to reactivate your account. You can request to recommence gambling after the Self-Exclusion period has elapsed, but you will subjected to a 24 hour cooling off period prior to the account being reactivated.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>Once a self-exclusion has been applied to an account, Community Gaming Limited will remove the customer's name from our mailing and marketing lists within 48 hours. We will endeavour to do everything we can to prevent a new account from an excluded customer from being opened during the period of self-exclusion.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>Should you opt to self-exclude from Community Gaming Limited, we strongly recommend that you Self-Exclude from all other gambling operators you have an account with.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>Once you have Self-Excluded from Community Gaming Limited we strongly advise that you delete or uninstall all CGL apps or downloads, ensuring that all of the notifications have also been disabled. We also recommend that you delete/remove/unfollow any and all Sportskins/Community Gaming Limited related social media.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>We also advise installing software that blocks access to gambling websites(details of which can be found below).</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>If you are concerned with the amount of time or money you spend gambling, or if you feel that you are at risk of developing a gambling problem or believe you currently have a gambling problem, we recommend that you seek support from a problem gambling support services (details of which can be found below) to help you deal with your problem.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span><span className={'fw-400 cabin'}>To use our self-exclusion facility and in order to ensure that any accounts you have chosen to be excluded from have been closed, subject to the Self Exclusion agreement, send an email FAO Martin Cross at&nbsp; </span><span
                            className={'fw-400 cabin'}>customerservices@communitygaming.co.uk</span><span
                            className={'fw-400 cabin'}>.</span></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>Further support and advice</strong></p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>GamCare</strong></p>
                        <p><span className={'fw-400 cabin'}>GamCare operates the National Gambling Helpline and offers confidential information, advice and support for anyone harmed by gambling in Great Britain. The Helpline is open 24 hours every day on Freephone 0808 8020 133 or via Live Chat if preferred. Advisers will listen to you, they won&rsquo;t judge and your conversation is confidential</span><a
                            href="http://www.gamcare.org.uk/"><span className={'fw-400 cabin'}> </span><span
                            className={'fw-400 cabin'}>www.gamcare.org.uk</span></a><span
                            className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>BeGambleAware</strong></p>
                        <p><span className={'fw-400 cabin'}>BeGambleAware provides information, advice and directs people to support to help keep people safe from gambling harms</span><a
                            href="http://www.begambleaware.org/"><span className={'fw-400 cabin'}> </span><span
                            className={'fw-400 cabin'}>www.begambleaware.org</span></a></p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>Gamblers Anonymous</strong></p>
                        <p><span className={'fw-400 cabin'}>Gamblers Anonymous provide confidential face-to-face counselling to anyone who is affected by problem gambling. Gamblers Anonymous can be contacted at </span><a
                            href="https://www.gamblersanonymous.org.uk/"><span
                            className={'fw-400 cabin'}>www.gamblersanonymous.org.uk.</span></a></p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>Gordon Moody</strong></p>
                        <p><span
                            className={'fw-400 cabin'}>Gordon Moody is a registered charity with more than 50 years&rsquo; experience in providing residential support and treatment for people who are severely addicted to gambling. We currently have two residential treatment centres, one in the West Midlands and the other on the Kent/London borders, as well as part-residential women and men specific programmes delivered in separate locations. We also provide specialist on-line support through our Gambling Therapy service and App. Gordon Moody can be contacted at</span><a
                            href="http://www.gordonmoody.org.uk/"><span className={'fw-400 cabin'}> </span><span
                            className={'fw-400 cabin'}>www.gordonmoody.org.uk</span></a><span
                            className={'fw-400 cabin'}> .</span></p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>GAMSTOP</strong></p>
                        <p><span className={'fw-400 cabin'}>If you are considering self-exclusion, you may wish to register with GAMSTOP. GAMSTOP is a free service that helps you self-exclude from all online gambling companies licensed in Great Britain. To find out more and to sign up with GAMSTOP please visit </span><a
                            href="https://www.gamstop.co.uk/"><span
                            className={'fw-400 cabin'}>www.gamstop.co.uk</span></a></p>
                        <p><strong>Website Blocking</strong></p>
                        <p><strong>GamBlock</strong></p>
                        <p><a href="http://www.gamblock.com/"><span
                            className={'fw-400 cabin'}>www.gamblock.com</span></a><span className={'fw-400 cabin'}>: Specific blocking software for gambling websites. Also available for Android devices.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>&nbsp;</span></p>
                        <p><strong>Betfilter</strong></p>
                        <p><a href="http://www.betfilter.com/"><span className={'fw-400 cabin'}>www.betfilter.com</span></a><span
                            className={'fw-400 cabin'}>: Specific blocking software for gambling websites. Also available for Android devices.</span>
                        </p>
                        <p><span className={'fw-400 cabin'}>You may also want to familiarise yourself with the built-in parental controls on your mobile, tablet, PC or TV.</span>
                        </p>
                        <p><strong>&nbsp;</strong></p>
                        <p><br/><br/></p>

                    </Container>
                </section>
            </div>
        );
    }

    return render();
}

export default ResponsibleGambling;
