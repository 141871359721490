import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";

const BottomFooter = () => {
    const render = () => {
        return (
            <section className={'mb-5'}>
                <Container>
                    <div className={'d-flex justify-content-evenly align-items-center'}>
                        <div>
                            <a href={'https://www.begambleaware.org/'} target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/wp-content/uploads/2021/11/Gamble-aware-Logo-footer.png')}
                                    className={'img-fluid'}
                                    alt="be_gamble_aware_footer_image"/>
                            </a>
                        </div>
                        <div>
                            <a href={'https://www.gamcare.org.uk/'} target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/wp-content/uploads/2021/11/logo4.png')}
                                    className={'img-fluid'}
                                    alt="gam_care footer_image"/>
                            </a>
                        </div>
                        <div>
                            <a href={'https://www.gamstop.co.uk/'} target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/wp-content/uploads/2021/11/logo3.png')}
                                    className={'img-fluid'}
                                    alt="gam_stop footer_image"/>
                            </a>
                        </div>
                        <div>
                            <a href={'https://www.ibas-uk.com/'} target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/wp-content/uploads/2021/11/logo2.png')}
                                    className={'img-fluid'}
                                    alt="ibas footer_image"/>
                            </a>
                        </div>
                        <div>
                            <a href={'https://www.gamblingcommission.gov.uk/'} target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/wp-content/uploads/2021/11/logo5.png')}
                                    className={'img-fluid'}
                                    alt="gambling_comission footer_image"/>
                            </a>
                        </div>
                        <div>
                            <a href={'https://www.w2globaldata.com/'} target={'_blank'} rel={'noreferrer'}>
                                <img
                                    src={require('../assets/images/wp-content/uploads/2021/10/w2.png')}
                                    className={'img-fluid'}
                                    alt="w2 footer_image"/>
                            </a>
                        </div>
                    </div>
                    <Row className={'mt-5 text-center'}>
                        <span className={'text-white syne font-18'}>
                            Community Gaming Ltd is licensed and regulated in Great Britain by the Gambling Commission under account number
                            <a href={'https://www.gamblingcommission.gov.uk/public-register/business/detail/58494'}
                               target={'_blank'} rel={'noreferrer'}
                               className={'cabin skins-link-red ms-2 font-18'}>
                                58494
                            </a>
                        </span>
                    </Row>
                </Container>
            </section>
        );
    }

    return render();
}

export default BottomFooter;
