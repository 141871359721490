import {Col, ListGroup, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {useEffect} from "react";

const SkinListGroupItem = (props) => {

    const {itemKey, text, image, isMobile} = props;

    useEffect(() => {
    }, []);
    const render = () => {
        return (
            <ListGroup.Item action key={itemKey}
                            eventKey={itemKey}
                            href={`#${itemKey}`}
                            onClick={(event) => {
                                event.preventDefault();
                                return false;
                            }}
                            className={`home-section-3-lg-item text-secondary font-17 border-0 border-bottom mt-2`}>
                <Row className={'p-3'}>
                    <Col className={'d-flex flex-column justify-content-center'} md={1}>
                        {
                            isMobile ?
                                <img src={(image)} alt={text} className={'img-fluid align-self-center'} height={60}
                                     width={60}/> :
                                <img src={(image)} alt={text} className={'img-fluid align-self-center'}/>
                        }
                    </Col>
                    <Col className={'d-flex flex-column justify-content-center'} md={10}>
                        {text}
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    }

    return render();
}

SkinListGroupItem.propTypes = {
    itemKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    current: PropTypes.bool.isRequired,
};

export default SkinListGroupItem;
