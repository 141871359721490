import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import LeaguePill from "../MySkinCards/CardItems/LeaguePill";
import SkinName from "../MySkinCards/CardItems/SkinName";
import {useEffect} from "react";

const SkinInfo = (props) => {
    const {isMobile, skin, currentWeek} = props;

    useEffect(() => {

    }, [currentWeek]);

    const renderMobile = () => {
        return (
            <section aria-label={'Skin Info'}>
                <Row>
                    <Col className={'text-end align-items-start d-flex text-white bebas font-30'}>
                        {currentWeek}
                    </Col>
                    <Col className={'text-center justify-content-center align-items-start'}>
                        <SkinName text={skin.name} fontSize={'font-30'} isMobile={isMobile}/>
                        <LeaguePill icon={skin.league.icon} name={skin.league.name} isMobile={isMobile}/>
                    </Col>
                    <Col className={'align-items-start'}>
                        <div
                            className={'bg-transparent text-white p-2 ps-3 pe-3 text-end justify-content-end d-flex align-items-start'}>
                            <span className={'bebas text-capitalize font-22 fw-bold'}>
                                {Intl.NumberFormat("en-GB", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 0,
                                    style: 'currency',
                                    currency: 'GBP'
                                }).format(skin.jackpot)}
                            </span>
                        </div>
                        <div className={'roboto font-14 text-skins-teal text-center ps-5 pe-3'}>
                            Prize
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }

    const renderDesktop = () => {
        return (
            <section aria-label={'Skin Info'}>
                <Row>
                    <Col className={'text-center align-items-center d-flex'}>
                        <LeaguePill icon={skin.league.icon} name={skin.league.name} isMobile={isMobile}/>
                    </Col>
                    <Col className={'text-center'}>
                        <SkinName text={skin.name} fontSize={'font-60'}/>
                    </Col>
                    <Col>
                        <div
                            className={'bg-transparent text-white p-2 ps-3 pe-3 text-end justify-content-end d-flex align-items-center'}>
                            <span className={'bebas text-capitalize font-43 fw-bold'}>
                                {Intl.NumberFormat("en-GB", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 0,
                                    style: 'currency',
                                    currency: 'GBP'
                                }).format(skin.jackpot)}
                            </span>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

SkinInfo.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    currentWeek: PropTypes.string,
    skin: PropTypes.shape({
        id: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        game_deadline: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        season: PropTypes.string.isRequired,
        price_per_pick: PropTypes.string.isRequired,
        weeks: PropTypes.string.isRequired,
        current_week: PropTypes.string.isRequired,
        jackpot: PropTypes.string.isRequired,
        entries: PropTypes.number.isRequired,
        winners: PropTypes.number.isRequired,
        losers: PropTypes.number.isRequired,
        league: PropTypes.shape({
            name: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            background_color: PropTypes.string.isRequired,
            font_color: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default SkinInfo;
