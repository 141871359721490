export const MATCH_STATES = {
    IN_PLAY: [2, 9, 10, 11],
    HT: 9,
    UPCOMING: [3],
    POSTPONED: [4],
    ABANDONED: [5],
    CANCELLED: [6],
    SUSPENDED: [7],
    INTERRUPTED: [8],
    FINISHED: [1],
};

export const CRICKET_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [0],
    FINISHED: [3, 8],
};

export const MLB_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const NBA_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const NCAA_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const NFL_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const MATCH_SAMPLE_DATA = {
    goalserve_id: 3837777,
    date: "2023-09-16",
    time: "15:00",
    state: 3,
    status: "15:00",
    is_postponed: false,
    score: "0 - 0",
    points: 0,
    local_team: {
        goalserve_id: 9175,
        name: "Fulham",
        alt_name: "Fulham",
        icon: "http://cdn.sportskins.uk/img/teams/goalserve-icons/9175.png"
    },
    visitor_team: {
        goalserve_id: 9253,
        name: "Luton",
        alt_name: "Luton",
        icon: "https://cdn.sportskins.uk/img/teams/goalserve-icons/9253.png"
    }
};

export const MATCH_SAMPLE_DATA_STARTED = {
    goalserve_id: 3837777,
    date: "2023-09-14",
    time: "15:00",
    state: 2,
    status: "55'",
    is_postponed: false,
    score: "3 - 2",
    points: 0,
    local_team: {
        goalserve_id: 9175,
        name: "Fulham",
        alt_name: "Fulham",
        icon: "http://cdn.sportskins.uk/img/teams/goalserve-icons/9175.png"
    },
    visitor_team: {
        goalserve_id: 9253,
        name: "Luton",
        alt_name: "Luton",
        icon: "https://cdn.sportskins.uk/img/teams/goalserve-icons/9253.png"
    }
};

export const SKIN_INFO_SAMPLE = {
    "id": "22",
    "uid": "1ee32390-54fc-6e90-bf41-0644dab55c3b",
    "name": "PREMSKINS1",
    "start_date": "03-Aug-2023",
    "end_date": "31-Dec-2023",
    "game_deadline": "2023-08-11 19:00:00",
    "status": "In Progress",
    "season": "2023/2024",
    "price_per_pick": "10",
    "weeks": "20",
    "current_week": "GW5",
    "jackpot": "82144.00",
    "entries": 9345,
    "winners": 3426,
    "losers": 183,
    "league": {
        "name": "Premier League",
        "icon": "https://cdn.sportskins.uk/public/premier_league/JiDa0smTQK9MHoQZhqNKLcD9Go7ci8gu4RiyCo9s.png",
        "background_color": "#110F22",
        "font_color": "#E94E1B"
    },
    "skin_toppers": []
}

export const MATCH_LIST_SAMPLE = {
    "id": "963",
    "goalserve_id": "3837759",
    "date": "2023-08-25",
    "time": "20:00:00",
    "status": "FT",
    "timer": "90+",
    "season": "2023/2024",
    "local_team_score": "3",
    "visitor_team_score": "0",
    "state": 1,
    "win_team_id": 9092,
    "total": "3 - 0",
    "pen_total": "",
    "et_total": "",
    "aggregate_score": "",
    "datetime": "2023-08-25 20:00:00",
    "league": {
        "id": "1204",
        "name": "Premier League",
        "icon": "https://cdn.sportskins.uk/public/premier_league/JiDa0smTQK9MHoQZhqNKLcD9Go7ci8gu4RiyCo9s.png"
    },
    "local_team": {
        "id": "10",
        "goalserve_id": "9092",
        "name": "Chelsea",
        "alt_name": "Chelsea",
        "abbreviation": "CHE",
        "icon": "https://cdn.sportskins.uk/img/teams/goalserve-icons/9092.png",
        "country": "England",
        "recent_form": [
            "D",
            "L",
            "W",
            "L",
            "D"
        ],
        "position": "14",
        "number_of_picks": 3227,
        "is_used_pick_1": true,
        "is_used_pick_2": false,
        "is_used_pick_3": false
    },
    "visitor_team": {
        "id": "26",
        "goalserve_id": "9253",
        "name": "Luton Town",
        "alt_name": "Luton",
        "abbreviation": "LUT",
        "icon": "https://cdn.sportskins.uk/img/teams/goalserve-icons/9253.png",
        "country": "England",
        "recent_form": [
            "L",
            "L",
            "L",
            "L"
        ],
        "position": "20",
        "number_of_picks": 0,
        "is_used_pick_1": false,
        "is_used_pick_2": false,
        "is_used_pick_3": false
    }
};
